var api = "https://api.qingcigame.com/web";
var index = '';
var go_page = window.location.href.split('?page=')[1];
var initialSlide = 0; //设置默认索引为0
var obj = {
    index: function() {
        this.preloading();  // 预加载
        this.indexAjax();   // 首页接口请求
        this.indexBind();   // 首页按钮
        this.newsBanner();  // 首页新闻数据
        this.newsTabPage(); // 最新、综合、活动跳转
        this.countNum();    // 阿里云埋点
    },
    // 新闻列表
    newsList: function() {
        this.newsZxList();  // 新闻最新列表
        this.newsTabPage(); // 最新、综合、活动跳转
    },
    // 新闻详情
    newsDetails: function() {
        this.newsInfo();    // 新闻详情
        this.newsTabPage(); // 最新、综合、活动跳转
    },
    // 图片资源预加载
    preloading: function() {
        //图片预加载
        var queue = new createjs.LoadQueue();
        queue.on("progress", function(e) {
            //加载中的进度
            if (e.progress * 100 >= 10) {
                $('.line span').css({ 'width': parseInt(e.progress * 100) + '%' });
            };
        }, this);
        queue.on("complete", function(e) {
            //加载完成执行操作
            $('.preloading').addClass('preloading-ani').fadeOut(1000);
            $('.index').addClass('index-show');
            this.indexSwiper();
            setTimeout(function() {
                $('.preloading').remove();
            }, 1000)
        }, this);
        queue.loadManifest([
            //加载图片，不包括后台的
            '/official/images/pc/code_img-a090f39473.png',
            '/official/images/pc/year_btn-6172f27dd2.png',
            '/official/images/pc/nav_all-b2f0817e92.png'
        ]);
    },
    // 首页接口请求
    indexAjax: function() {
        var _this = this;
        $.ajax({
            url: api + "/lost/index",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                console.log(json)
                if (json.code == 200) {
                    var gonggao = json.data.articleList.gong_gao; //公告
                    var activity = json.data.articleList.activity_news; //活动

                    // 【游戏资讯】公告
                    $.each(gonggao, function(index, values) {
                        var gonggaoHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<span>' + values.created_at + '</span>' +
                            '</a>' +
                            '</li>';
                        $('.zonghe').append(gonggaoHtml);
                    });

                    // 【游戏资讯】活动
                    $.each(activity, function(index, values) {
                        var activityHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<span>' + values.created_at + '</span>' +
                            '</a>' +
                            '</li>';
                        $('.activity').append(activityHtml);
                    });

                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
        jQuery(".slideTxtBox").slide({ effect: "left", trigger: "click", easing: "swing" });
    },
    // 首页按钮
    indexBind: function() {
        $('.monitor-btn').on('click', function() {
            var mon_btn = $(this).attr('data-event');
            console.log(mon_btn)
            obj.countNum(mon_btn);
        })

        // 视频弹窗
        var Media = document.getElementById("media-play");
        $('.video-btn').click(function(event) {
            $('.video-popup').fadeIn();
            Media.play();
        });

        // 适龄弹窗
        $('.year-btn').click(function(event) {
            $('.year_pop').fadeIn();
        });

        // 二维码弹窗
        $('.gzh-btn').click(function(event) {
            $('.gzh-popup').fadeIn();
        });

        // 企微弹窗
        $('.qw-btn').click(function(event) {
            $('.qw-popup').fadeIn();
        });

        // 官方Q群弹窗
        $('.qq-btn').click(function(event) {            
            $.ajax({
                type: "post",
                url: 'https://api.qingcigame.com/channel/join/group?game_id=50',
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    $('.qq-popup').fadeIn();
                    $('.qq-popup img').attr('src', json.data.channel_url.qq_image);
                }
            });            
        });

        // 关闭弹窗
        $('.popup').on('click', '.close-btn', function(event) {
            $('.popup').fadeOut();
            Media.pause();
        });

        var taptap_url = 'https://l.taptap.cn/5Rqq3SMV?channel=rep-rep_jchubcjmygh';
        var hykb_url = 'https://www.3839.com/a/164372.htm';
        $('.top-code-box').on('click', 'a', function() {
            var className = $(this).attr('data-event');
            var game_id = 50;
            $.ajax({
                type: "get",
                url: 'https://mapi.qingcigame.com/get_url?game_id=' + game_id + '',
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (className == 'ios-download') {
                        // ios
                        if (json.data.ios_down_url == '' || json.data.ios_down_url == null) {
                            layer.msg('敬请期待！', { time: 3000 });
                        } else {
                            location.href = json.data.ios_down_url
                        }
                    }
                    if (className == 'and-download') {
                        // 安卓
                        if (json.data.android_down_url == '' || json.data.android_down_url == null) {
                            layer.msg('敬请期待！', { time: 3000 });
                        } else {
                            location.href = json.data.android_down_url
                        }
                    }

                }
            });
            if (className == 'taptap-download') {
                location.href = taptap_url;
            }
        });

        $('.taptap-btn').click(function() {
            setTimeout(() => {
                location.href = taptap_url;
            }, 200);
        })

        $('.hykb-btn').click(function() {
            setTimeout(() => {
                location.href = hykb_url;
            }, 200);
        })
    },
    // 首页新闻数据
    newsBanner: function() {
        $.ajax({
            url: "https://api.qingcigame.com/web/news/info",
            type: "GET",
            data: {
                game_id: 50
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var article = json.data.article;
                    $.each(article.game_information, function(index, values) {
                        var gameInfoIndex = `<div class="swiper-slide swiper-no-swiping">
                        <a  href="${values.jump_url}">
                        <img src="${values.pc_image}">
                        <span></span>
                        </a>
                        <p>${values.name}</p>
                        </div>`;
                        $('.swiper-container-news .swiper-wrapper').append(gameInfoIndex);
                    });
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }
            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },    
    // 最新、综合、活动跳转
    newsTabPage: function() {
        $('.news .tab-title').on('click', 'a', function(event) {
            $(this).addClass('on').siblings('a').removeClass('on');
            var cate_id = $(this).attr('cate-id');
            window.location.href = window.location.href.split("?")[0] + '?cate_id=' + cate_id + '&page=1'
        });

        var navName = $('.nav-box');
        navName.addClass('on');
        // 导航切换
        $('.change-btn').click(function() {
            navName.hasClass('on') ? navName.removeClass('on') : $('.nav-box').addClass('on');
        })

        // 重定向
        $('.secondary-page-nav').on('click', '.nav-link', function(event) {
            var page_id = $(this).attr('data');
            console.log(window.location.host)
            window.location.href = 'https://mtzg.qingcigame.com/index.html?index=' + page_id;
        });

        $('.return-top, .top-btn').click(function(event) {
            $(document).scrollTop(0);
        });

        function checkScreenWidth() {
            var screenWidth = window.innerWidth;
            screenWidth < 1600 ? $('.nav-box').removeClass('on') : $('.nav-box').addClass('on');
        }
        checkScreenWidth();
        window.addEventListener('resize', checkScreenWidth);  
    },
    // 首页轮播
    indexSwiper: function() {
        if (window.location.href.includes('?')) 
            initialSlide = this.getQueryVariable("index") || 0;
        
        function Ani(btn, page, swiperInstance) {
            $(btn).on('click', function() {
                swiperInstance.slideTo(page, 500, true);
            });
        }        
        function navLink(swiperInstance) {
            for (let i = 6; i >= 0; i--) {
                Ani(`.nav-link-0${i} .link-a`, i, swiperInstance);
            }
        }        
        const aniLinks = [
            { selector: '.nav-box .top-btn', index: 0 },
            { selector: '.arrow-b', index: 1 },
            { selector: '.arrow-b2', index: 2 },
            { selector: '.arrow-b3', index: 3 }
        ];        
        function setupAniLinks(swiperInstance) {
            aniLinks.forEach(link => Ani(link.selector, link.index, swiperInstance));
        }        
        function initSwiper(swiperContainer, swiperOptions) {
            return new Swiper(swiperContainer, swiperOptions);
        }        
        const mySwiperOptions = {
            pagination: '.swiper-pagination',
            direction: 'vertical',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: 0,
            mousewheel: true,
            resistanceRatio: 0,
            initialSlide: initialSlide, // 假设 initialSlide 已经在某处定义
            touchMoveStopPropagation: false,
            touchStartForcePreventDefault: true,
            on: {
                init: function() {
                    swiperAnimate(this); // 假设 swiperAnimate 已经在某处定义
                    navLink(this);
                    setupAniLinks(this);
                },
                slideChangeTransitionEnd: function() {
                    $('.nav-link').removeClass('on');
                    $(`.nav-link-0${this.activeIndex}`).addClass('on');
                },
                transitionStart: function() {
                    swiperAnimate(this);
                },
            },
        };        
        const mySwiper = initSwiper('.swiper-container', mySwiperOptions);
        
        const newsSwiperOptions = {
            loop: true,
            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination2',
                clickable: true,
            },
            slidesPerView: 'auto',
        };
        
        setTimeout(() => {
            const newsSwiper = initSwiper('.swiper-container-news', newsSwiperOptions);
        }, 500);
        
        const charitySwiperOptions = {
            loop: true,
            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination4',
                clickable: true,
            },
            noSwiping: true,
            autoplayDisableOnInteraction: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            effect: 'coverflow',
            centeredSlides: true,
            slidesPerView: 'auto',
            loopedSlides: 5,
            observer: true,
            observerParents: true,
            speed: 500,
            coverflowEffect: {
                rotate: 0,
                stretch: 30,
                depth: 10,
                modifier: 15,
                slideShadows: false
            },
            on: {
                init: function() {
                    swiperAnimate(this);
                },
            },
        };
        
        const charitySwiper = initSwiper('.swiper-container-feature', charitySwiperOptions);        
    },
    // 获取当前hash的具体参数
    getQueryVariable: function(variable) {
        var query = window.location.href.split('?')[1];
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    // 新闻最新接口
    newsZxList: function() {
        var cate_id, page, current, index;
        cate_id = obj.getQueryVariable('cate_id');
        page = obj.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, { shade: [0.1, '#fff'] });
        $('.news .tab-title .a-tab-' + cate_id + '').addClass('on').siblings('a').removeClass('on');
        $.ajax({
            // url: api + "/article",
            url: "https://api.qingcigame.com/web/article",
            type: "GET",
            data: {
                game_id: 50,
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var current_page = json.data.articleList.current_page; // 当前页数
                    var last_page = json.data.articleList.last_page; // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url; // 前一页
                    var next_page_url = json.data.articleList.next_page_url; // 后一页
                    var _data = json.data.articleList.data;
                    if (_data.length == 0) {
                        $('.main').addClass('list-null');
                    } else {
                        $('.main').removeClass('list-null');
                        // 渲染列表
                        $.each(_data, function(index, values) {
                            var dtHtml = '<li>' +
                                '<a title="' + values.article_title + '" href="news-details.html?id=' + values.id + '">' +
                                '<p class="lside">' +
                                '<span class="time">' + values.created_at + '</span>' +
                                '<span class="title" style="display: block;">' + values.article_title + '</span>' +
                                '</p>' +
                                '</a>' +
                                '</li>';
                            $('.new-list ul').append(dtHtml);
                        });
                        $('.new-list').addClass('new-list-show');
                        $('.current_page').html(current_page); // 当前页数
                        $('.last_page').html(last_page); // 总共页数
                        if (prev_page_url === null) { // 上一页
                            $('.front-link').hide(); // 没有第一页
                        } else {
                            $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
                        }
                        if (next_page_url === null) { // 下一页
                            $('.after-link').hide(); // 没有最后一页
                        } else {
                            $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
                        }
                        $('.new-list').addClass('new-list-show');
                    }
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    analysisParams: function(url) {
        const params = {};
        const paramsString = url.split('?')[1];
        if (!paramsString) {
            return params;
        }
        const str = paramsString.split('&');
        for (let idx = 0; idx < str.length; idx++) {
            const values = str[idx].split('=');
            params[values[0]] = values[1];
        }
        return params;
    },
    // 新闻详情
    newsInfo: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: "https://api.qingcigame.com/web/article/" + id + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                $('.news-det .main').addClass('main-show');
                if (json.code == 200) {
                    var newsTitle = json.data.list.article_title; // 标题
                    var detailsInfo = json.data.list.article_content; // 内容
                    var origin = json.data.list.article_excerpt; // 作者
                    var created_at = json.data.list.created_at; // 时间
                    $('.news .news-title h3, .current-title').html(newsTitle);
                    $('.news .origin').html(origin);
                    $('.news .created_at').html(created_at);
                    $('.details-info').append(detailsInfo);

                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    $('title').html(newsTitle);
                    $('meta[name="keywords"]').attr('content', json.data.list.article_keywords);
                    $('meta[name="description"]').attr('content', json.data.list.article_excerpt);
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 翻页功能
    pageDown: function(lastPage, nextPage, cite) {
        var href = window.location.href.split("?")[0] + '?' + cite + '='
        if (lastPage !== '') $('.prev-page').addClass('show').attr('href', href + lastPage.id).attr('title', lastPage.article_title);
        if (nextPage !== '') $('.next-page').addClass('show').attr('href', href + nextPage.id).attr('title', nextPage.article_title);
    },
    share: function() {
        var share_icon = "https://download4.qcplay.com/qcgame-api/nqQ1a6eOF3VlSt9WdbIxhOimXQ9nKubjPmBsvSpe.png"
        var share_big_title = "迷途之光"
        var share_min_title = "超爽肉鸽像素弹幕射击"
        var share_link = window.location.href
        outside.share(share_icon, share_big_title, share_min_title, share_link)
    },
    // 阿里云埋点
    countNum: function(data) {
        setTimeout(() => {
            try {
                __bl.sum(data);
            } catch (e) {
                console.log(e)
            }
        }, 500);
    }
}